






































export default

	props:
		options: Array
		variants: Array
		currentVariant: Object
		loading: Boolean
		purchaseType: String
		suppressUrlWatch:
			type: Boolean
			default: false

	# Store their choices
	data: -> choices: {}

	computed:

		line1: ->
			if @currentVariant?.variant_line_1? then return @currentVariant?.variant_line_1.value
			else return @currentVariant.title
		line2: -> if @currentVariant?.variant_line_2? then return @currentVariant?.variant_line_2.value
		description_otp: -> if @currentVariant?.variant_description_otp? then return @currentVariant?.variant_description_otp.value
		description_recurring: -> if @currentVariant?.variant_description_recurring? then return @currentVariant?.variant_description_recurring.value

		hasCustomVariantInfo: -> @currentVariant?.variant_line_1?

		# Determine what the current variant should be based on the form choices
		chosenVariant: -> @variants.find (variant) =>
			for option in variant.selectedOptions
				return false if option.value != @choices[option.name]
			return true

		# Filter out options that are out of stock and unavailable
		_options: ->
			result = {}
			@options.forEach (option) =>
				_option =
					name: option.name
					values: option.values.filter (value) =>
						@isOptionAvailable(option.name, value)
				_option.choices = _option.values.map (value) => {
					label: value
					value
				}
				result[option.name] = _option
			return result



	watch:

		# Set the current choices state based on the currentVariant
		'currentVariant.selectedOptions':
			immediate: true
			handler: (options) ->
				for option in options
					@$set @choices, option.name, option.value

		# When the user chooses a new option, which updates the chosenVariant,
		# make this the new current variant
		chosenVariant: ->

			# Update the route without doing a true Vue navigation
			unless @suppressUrlWatch
				history.replaceState {}, null, @chosenVariant.url

			# Tell parent about the new choice
			@$emit 'input', @chosenVariant

	methods:

		# If the option is title, and there's only 1 variant in the
		# option, then don't render that block of options
		isMultipleVariants: (option) ->
			if option.name == 'Title' && option.choices.length == 1 then return false
			else return true

		# Determine if the given option is available. Given the other selected
		# options, does a variant exist for this option?
		isOptionAvailable: (name, value) ->

			# Loop through the variant options, looking for one who matches this name
			# and value and also has the same options choices as the chosen variant.
			!!@variants.find (variant) =>
				return false unless variant.available

				for testOption in variant.selectedOptions

					# If this variant option matches the type that we're checking, it
					# has to have the same value
					if testOption.name == name and
						testOption.value != value
					then return false

					# If the variant option is a different type, it must match that of
					# the currently chosen type
					chosenValueForTestOption = @chosenVariant.selectedOptions
						.find (option) -> option.name == testOption.name
						.value
					if testOption.name != name and
						testOption.value != chosenValueForTestOption
					then return false

				# This variant has a matching value for the option name as well as
				# matching values for the other chosen options
				return true


