









































import DetachableHeader from 'vue-detachable-header'
import 'vue-detachable-header/index.css'
import dimensions from 'library/assets/vars/dimensions'
import SiteLogo from './site-logo'
import CartIcon from './cart-icon'
import MobileNav from './nav/mobile/main-nav'
import Hamburger from './nav/mobile/hamburger'
import HeaderDropdown from './header-dropdown'
import SearchDropdown from './search-dropdown'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'
import ClickOutside from 'vue-click-outside'
import Banner from 'library/components/layout/header/site-banner'
import getBanner from 'library/queries/craft/banner.gql'

export default

	components: {
		DetachableHeader
		SiteLogo
		CartIcon
		MobileNav
		Hamburger
		HeaderDropdown
		SearchDropdown
		Banner
	}

	directives: {
		ClickOutside
	}

	data: ->
		# height: parseInt dimensions['header-h-mobile']
		menuOpen: false
		searchOpen: false

	computed:
		showBanner: -> @$store?.state?.globals?.banner?
		height: ->
			if @showBanner then return @headerH + @bannerH
			else return @headerH

		headerH: -> parseInt dimensions['header-h-mobile']
		bannerH: -> parseInt dimensions['banner-h-mobile']

	methods:
		openCart: ->
			@$store.commit('cart/open')

		toggleMobileNav: ->
			if @menuOpen
				@menuOpen = false
				clearAllBodyScrollLocks()
			else
				@menuOpen = true

			@$refs.hamburger?.toggle(@menuOpen)


		toggleSearch: -> @searchOpen = !@searchOpen
		closeSearch: -> @searchOpen = false

		clickOutside: -> @closeSearch()

	watch:

		menuOpen: ->
			# Set modalOpen.  Adds aria-hidden to 'main'.
			@$store.commit('ui/setMobileMenuOpen', @menuOpen)

			# Update body scroll lock
			if !@menuOpen
				clearAllBodyScrollLocks()
				@$refs.hamburger?.toggle(false)

		# Close the nav when the route changes
		$route: ->
			@menuOpen = false

